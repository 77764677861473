import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import ButtonActive from '../../examples/Button/Active';
import ButtonBlock from '../../examples/Button/Block';
import ButtonDisabled from '../../examples/Button/Disabled';
import ButtonLoading from '../../examples/Button/Loading';
import ButtonOutlineTypes from '../../examples/Button/OutlineTypes';
import ButtonSizes from '../../examples/Button/Sizes';
import ButtonTagTypes from '../../examples/Button/TagTypes';
import ToggleButton from '../../examples/Button/ToggleButton';
import ToggleButtonGroupControlled from '../../examples/Button/ToggleButtonGroupControlled';
import ToggleButtonGroupUncontrolled from '../../examples/Button/ToggleButtonGroupUncontrolled';
import ButtonTypes from '../../examples/Button/Types';
export const query = graphql`
  query ButtonQuery {
    Button: componentMetadata(displayName: { eq: "Button" }) {
      displayName
      ...ComponentApi_metadata
    }
    ToggleButtonGroup: componentMetadata(
      displayName: { eq: "ToggleButtonGroup" }
    ) {
      displayName
      ...ComponentApi_metadata
    }
    ToggleButton: componentMetadata(displayName: { eq: "ToggleButton" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "buttons"
    }}>{`Buttons`}</h1>
    <p className="lead">
  Custom button styles for actions in forms, dialogs, and more with
  support for multiple sizes, states, and more.
    </p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`Use any of the available button style types to quickly create a styled
button. Just modify the `}<inlineCode parentName="p">{`variant`}</inlineCode>{` prop.`}</p>
    <ReactPlayground codeText={ButtonTypes} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "outline-buttons"
    }}>{`Outline buttons`}</h3>
    <p>{`For a lighter touch, Buttons also come in `}<inlineCode parentName="p">{`outline-*`}</inlineCode>{`
variants with no background color.`}</p>
    <ReactPlayground codeText={ButtonOutlineTypes} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "button-tags"
    }}>{`Button tags`}</h2>
    <p>{`Normally `}<inlineCode parentName="p">{`<Button>`}</inlineCode>{` components will render a HTML
`}<inlineCode parentName="p">{`<button>`}</inlineCode>{` element. However you can render whatever you'd
like, adding a `}<inlineCode parentName="p">{`href`}</inlineCode>{` prop will automatically render an
`}<inlineCode parentName="p">{`<a />`}</inlineCode>{` element. You can use the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop to
render whatever your heart desires. React Bootstrap will take care of
the proper ARIA roles for you.`}</p>
    <ReactPlayground codeText={ButtonTagTypes} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <p>{`Fancy larger or smaller buttons? Add `}<inlineCode parentName="p">{`size="lg"`}</inlineCode>{`,
`}<inlineCode parentName="p">{`size="sm"`}</inlineCode>{` for additional sizes.`}</p>
    <ReactPlayground codeText={ButtonSizes} mdxType="ReactPlayground" />
    <p>{`Create block level buttons—those that span the full width of a parent—by
adding `}<inlineCode parentName="p">{`block`}</inlineCode></p>
    <ReactPlayground codeText={ButtonBlock} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "active-state"
    }}>{`Active state`}</h2>
    <p>{`To set a button's active state simply set the component's
`}<inlineCode parentName="p">{`active`}</inlineCode>{` prop.`}</p>
    <ReactPlayground codeText={ButtonActive} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "disabled-state"
    }}>{`Disabled state`}</h3>
    <p>{`Make buttons look inactive by adding the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop to.`}</p>
    <ReactPlayground codeText={ButtonDisabled} mdxType="ReactPlayground" />
    <p>{`Watch out! `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` elements don't naturally support a
`}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute. In browsers that support it this is handled with a
`}<inlineCode parentName="p">{`point-events: none`}</inlineCode>{` style but not all browsers support it
yet.`}</p>
    <p>{`React Bootstrap will prevent any `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` handlers from
firing regardless of the rendered element.`}</p>
    <h2 {...{
      "id": "button-loading-state"
    }}>{`Button loading state`}</h2>
    <p>{`When activating an asynchronous action from a button it is a good UX
pattern to give the user feedback as to the loading state, this can
easily be done by updating your `}<inlineCode parentName="p">{`<Button />`}</inlineCode>{`s
props from a state change like below.`}</p>
    <ReactPlayground codeText={ButtonLoading} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "checkbox--radio"
    }}>{`Checkbox / Radio`}</h2>
    <p>{`Buttons can also be used to style `}<inlineCode parentName="p">{`checkbox`}</inlineCode>{` and
`}<inlineCode parentName="p">{`radio`}</inlineCode>{` form elements. This is helpful when you want a toggle
button that works neatly inside an HTML form.`}</p>
    <ReactPlayground codeText={ToggleButton} mdxType="ReactPlayground" />
    <p>{`The above handles styling, But requires manually controlling the
`}<inlineCode parentName="p">{`checked`}</inlineCode>{` state for each radio or checkbox in the group.`}</p>
    <p>{`For a nicer experience with checked state management use the
`}<inlineCode parentName="p">{`<ToggleButtonGroup>`}</inlineCode>{` instead of a `}<inlineCode parentName="p">{`<ButtonGroup toggle>`}</inlineCode>{` component.
The group behaves as a form component, where the `}<inlineCode parentName="p">{`value`}</inlineCode>{` is an array of the selected
`}<inlineCode parentName="p">{`value`}</inlineCode>{`s for a named checkbox group or the single toggled
`}<inlineCode parentName="p">{`value`}</inlineCode>{` in a similarly named radio group.`}</p>
    <h4 {...{
      "id": "uncontrolled"
    }}>{`Uncontrolled`}</h4>
    <ReactPlayground codeText={ToggleButtonGroupUncontrolled} mdxType="ReactPlayground" />
    <h4 {...{
      "id": "controlled"
    }}>{`Controlled`}</h4>
    <ReactPlayground codeText={ToggleButtonGroupControlled} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Button} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ToggleButtonGroup} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ToggleButton} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      